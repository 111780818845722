import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

export type SessionState = {
    code: string;
    groupCode: string;
    groupDetailCode: string;
};

export type SessionActions = {
    setCode: (code: string) => void;
    setGroupCode: (groupCode: string) => void;
    setGroupDetailCode: (groupDetailCode: string) => void;
};

export type SessionStore = SessionState & SessionActions;

export const initSessionStore = (): SessionState => {
    return {
        code: '',
        groupCode: '',
        groupDetailCode: '',
    };
};

export const defaultInitState: SessionState = {
    code: '',
    groupCode: '',
    groupDetailCode: '',
};

export const createSessionStore = (initState: SessionState = defaultInitState) => {
    return createStore<SessionStore>()(
        persist(
            (set, get) => ({
                ...initState,
                setCode: (code: string) =>
                    set((state) => ({
                        code: code,
                    })),
                setGroupCode: (code: string) =>
                    set((state) => ({
                        groupCode: code,
                    })),

                setGroupDetailCode: (code: string) =>
                    set((state) => ({
                        groupDetailCode: code,
                    })),
            }),
            {
                name: '_session',
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    );
};
